import React, { useContext, useEffect } from 'react';
import TagManager from 'react-gtm-module';
import { LocationContext } from '../Location/Location';

const initializeGtm = country => {
  const isUs = country === 'us';
  const isMx = country === 'mx';
  const isAr = country === 'ar';
  const isCo = country === 'co';

  if (isUs) {
    const GTM = process.env.REACT_APP_GTM_US;
    const tagManagerArgs = {
      gtmId: GTM,
      auth:'',
      preview:'',
      cookiesWin:'',
      dataLayerName: 'dataLayer'
    };
    TagManager.initialize(tagManagerArgs);
  }
  if (isMx) {
    const GTM = process.env.REACT_APP_GTM_MX;
    const tagManagerArgs = {
      gtmId: GTM,
      dataLayerName: 'PageDataLayer'
    };
    TagManager.initialize(tagManagerArgs);
  }
  if (isAr) {
    const GTM = process.env.REACT_APP_GTM_AR;
    const tagManagerArgs = {
      gtmId: GTM,
      dataLayerName: 'PageDataLayer'
    };
    TagManager.initialize(tagManagerArgs);
  }
  if (isCo) {
    const GTM = process.env.REACT_APP_GTM_CO;
    const tagManagerArgs = {
      gtmId: GTM,
      dataLayerName: 'PageDataLayer'
    };
    TagManager.initialize(tagManagerArgs);
  }
};

const GTM = () => {
  const { country } = useContext(LocationContext);

  useEffect(() => {
    initializeGtm(country);
  }, []);

  return <></>;
};

export default GTM;
