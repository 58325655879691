import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import { flushSync } from 'react-dom';
import * as actions from '../../actions';
import { initialState } from './inititalState';
import { getAmountFormat } from '../../utils/currencyISOCode';

export const StateContext = React.createContext({
  actions,
  state: initialState(),
  utils: {
    getFormDataStep1,
    getFormDataStep2,
    getFormDataStep3,
    getStep,
    getExpire,
    isExpired,
    getAmountFormated,
    clearLocalStore,
  },
});

const associateActions = (component, actionList) => {
  const associatedActions = {};
  Object.keys(actionList).forEach((key) => {
    if (typeof actionList[key] === 'function') {
      associatedActions[key] = actionList[key].bind(component);
    }
    if (typeof actionList[key] === 'object') {
      associatedActions[key] = associateActions(component, actionList[key]);
    }
  });
  return associatedActions;
};

function getFormDataStep1() {
  const { form1 } = this.state.formDataCotizar;
  return form1;
}

function getFormDataStep2() {
  const { form2 } = this.state.formDataCotizar;
  return form2;
}

function getFormDataStep3() {
  const { form3 } = this.state.formDataCotizar;
  return form3;
}

function getStep() {
  const { step } = this.state.step;
  return step;
}

function getExpire() {
  const { expire } = this.state.expire;
  return expire;
}

function isExpired() {
  const { expire } = this.state.expire;
  return expire ? moment().isAfter(expire) : false;
}

function getAmountFormated() {
  const { form3 } = this.state.formDataCotizar;
  return getAmountFormat(form3.data.amount);
}

function getAmountWithBenefitsFormated() {
  const { form3 } = this.state.formDataCotizar;
  if (form3.data.amountWithBenefits) {
    return getAmountFormat(form3.data.amountWithBenefits);
  }
  return getAmountFormat(form3.data.amount);
}

function clearLocalStore() {
  localStorage.removeItem('formDataCotizar');
  localStorage.removeItem('step');
  localStorage.removeItem('expire');
}

// TODO: convert this to functional component
export class StateContextParent extends Component {
  constructor(props) {
    super(props);

    // eslint-disable-next-line react/destructuring-assignment
    if (!this.props.initialState) this.state = initialState();
    // eslint-disable-next-line react/destructuring-assignment
    else this.state = this.props.initialState;
    this.actions = associateActions(this, actions);

    this.utils = {
      getFormDataStep1: getFormDataStep1.bind(this),
      getFormDataStep2: getFormDataStep2.bind(this),
      getFormDataStep3: getFormDataStep3.bind(this),
      getStep: getStep.bind(this),
      getExpire: getExpire.bind(this),
      isExpired: isExpired.bind(this),
      getAmountFormated: getAmountFormated.bind(this),
      getAmountWithBenefitsFormated: getAmountWithBenefitsFormated.bind(this),
      clearLocalStore: clearLocalStore.bind(this),
    };
  }
  // eslint-disable-next-line
  setStateAsync(state) {
    return new Promise((resolve) => {
      flushSync(() => {
        this.setState(state, resolve);
      });
    });
  }

  UNSAFE_componentWillMount() {
    const expire = JSON.parse(localStorage.getItem('expire')) || this.utils.getExpire();
    if (expire && expire.expire) {
      this.actions.expire.setExpire(expire.expire);
      if (moment().isAfter(expire.expire)) {
        this.utils.clearLocalStore();
      } else {
        const formDataCotizar = JSON.parse(
          localStorage.getItem('formDataCotizar'),
        );
        const step = JSON.parse(localStorage.getItem('step'));
        if (step && step.step === 4) {
          this.utils.clearLocalStore();
        } else {
          const { Quote } = formDataCotizar.form1;
          const form2data = formDataCotizar.form2;
          const form3data = formDataCotizar.form3;
          this.actions.formDataCotizar.setDataForm(
            { Quote },
            form2data,
            form3data,
          );
          this.actions.step.setStep(step.step);
        }
      }
    } else {
      this.utils.clearLocalStore();
    }
  }

  render() {
    const value = {
      state: this.state,
      actions: this.actions,
      utils: this.utils,
    };
    return (
      <StateContext.Provider value={value}>
        {/* eslint-disable-next-line react/destructuring-assignment */}
        {this.props.children}
      </StateContext.Provider>
    );
  }
}

export function IntlStateContextParent(props) {
  return <StateContextParent {...props} />;
}

export default withRouter(IntlStateContextParent);
