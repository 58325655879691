import { langAr, langCo, langMx, langUs } from '../location/urls';

export const ISOAr = 'ARS';
export const ISOCo = 'COP';
export const ISOMx = 'MXN';
export const ISOUs = 'USD';

export const getCurrencyByFormType = formType => {
  let currencyValue;

  switch (formType) {
    case langAr:
      currencyValue = ISOAr;
      break;

    case langCo:
      currencyValue = ISOCo;
      break;

    case langMx:
      currencyValue = ISOMx;
      break;

    case langUs:
      currencyValue = ISOUs;
      break;

    default:
      currencyValue = ISOCo;
      break;
  }
  return currencyValue;
};

export const getAmountFormat = amount => {
  return amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
};
