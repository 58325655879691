import moment from 'moment';

export async function setForm1(data) {
  const formDataCotizar = { ...this.state.formDataCotizar };
  formDataCotizar.form1 = data;
  await this.setStateAsync({ formDataCotizar });
  localStorage.setItem('formDataCotizar', JSON.stringify(formDataCotizar));
}

export async function setForm2(data) {
  const formDataCotizar = { ...this.state.formDataCotizar };
  formDataCotizar.form2 = data;
  await this.setStateAsync({ formDataCotizar });
  localStorage.setItem('formDataCotizar', JSON.stringify(formDataCotizar));
}

export async function setForm3(data) {
  const formDataCotizar = { ...this.state.formDataCotizar };
  formDataCotizar.form3 = data;
  await this.setStateAsync({ formDataCotizar });
  localStorage.setItem('formDataCotizar', JSON.stringify(formDataCotizar));
}

export async function setDataForm(form1 = '', form2 = '', form3 = '') {
  const formDataCotizar = { ...this.state.formDataCotizar };
  formDataCotizar.form1 = form1;
  formDataCotizar.form2 = form2;
  formDataCotizar.form3 = form3;
  await this.setStateAsync({ formDataCotizar });
  localStorage.setItem('formDataCotizar', JSON.stringify(formDataCotizar));
}

export async function setOptionalBenefits(benefitIndex, select) {
  const formDataCotizar = { ...this.state.formDataCotizar };
  const { data } = formDataCotizar.form3;
  data.optionalBenefits[benefitIndex].detailToPlan.selected = select;
  await this.setStateAsync({ formDataCotizar });
  localStorage.setItem('formDataCotizar', JSON.stringify(formDataCotizar));
}
