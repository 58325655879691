import moment from 'moment';

export async function setExpire(
  setValue = moment()
    .add(1, 'hour')
    .toString()
) {
  const expire = { ...this.state.expire, expire: setValue };
  await this.setStateAsync({ expire });
  localStorage.setItem('expire', JSON.stringify(expire));
}
