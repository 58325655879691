import React, { useContext } from 'react';
import { LocationContext } from '../components/Location/Location';

export const IntlContext = React.createContext({
  translate: translations => {
    return translations;
  },
  getCountryPrefix: () => {}
});

export const translate = (translations, idiom) => {
  const translated = {};
  const keys = Object.keys(translations);
  keys.forEach(key => {
    translated[key] = translations[key][idiom];
  });
  return translated;
};

export const getCountryPrefix = idiom =>
  idiom.substring(idiom.length - 2, idiom.length);

export const IntlParent = props => {
  const { idiom } = useContext(LocationContext);
  const translated = translations => translate(translations, idiom);
  const getCountry = () => getCountryPrefix(idiom);

  return (
    <IntlContext.Provider
      value={{
        translate: translated,
        getCountryPrefix: getCountry
      }}
    >
      {props.children}
    </IntlContext.Provider>
  );
};
