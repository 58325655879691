import React, { useContext, useEffect } from 'react';
import { gtag, install } from 'ga-gtag';
import { LocationContext } from '../Location/Location';

const initializeGtm = country => {
    
  const isAr = country === 'ar';

  if (isAr) {
    const GTMPixelId = process.env.REACT_APP_GTM_PIXELS_AR;
    install(GTMPixelId);
    window.dataLayer = window.dataLayer || [];
    gtag('js', new Date());   
    gtag('config', GTMPixelId);
  }  
};

const GTMPixels = () => {
  const { country } = useContext(LocationContext);

  useEffect(() => {
    initializeGtm(country);
  }, []);

  return <></>;
};

export default GTMPixels;
