import React from 'react';
import styles from './LoadingCircle.module.scss';
import iconCircle from '../../assets/img/loading.gif';

const LoadingCircle = props => {
  const { dataTestId = 'loadingCircle', message } = props;
  const msg = message ? message : '';
  return (
    <div className={styles.wrapLoading} ref={props.ref}>
      <div className={styles.loadingCircle} data-testid={dataTestId}>
        <img loading="lazy" src={iconCircle} height="80px" width="80px" alt="_" />
        <h4 className={styles.loadingMsg}>{msg}</h4>
      </div>
    </div>
  );
};

export const LoadingCircleWithoutPopUp = props => {
  const { dataTestId = 'loadingCircle', message } = props;
  const msg = message ? message : '';
  return (
      <div className={styles.loadingCircle} data-testid={dataTestId}>
        <img loading="lazy" src={iconCircle} alt="_" />
        <h4 className={styles.loadingMsg}>{msg}</h4>
      </div>
  );
};

export default LoadingCircle;
